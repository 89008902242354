<template>
  <b-container class="container-content card-container shadow-lg">
    <div class="page-header">
      <h4 class="page-header-text">Payment method</h4>
    </div>
    <b-row>
      <b-col xl="6" class="px-4">
        <b-form-group label="Coupon">
          <b-input placeholder="Apply Coupon"></b-input>
        </b-form-group>
      </b-col>
      <b-col xl="6" class="px-4">
        <b-form-group>
          <div class="d-flex justify-content-between mt-2">
            <p class="mb-0">Total (THB-฿)</p>
            <p class="mb-0">฿ 570.00</p>
          </div>
          <hr />
        </b-form-group>
        <b-form-group>
          <h5 class="font-weight-bold">Cancel Policy</h5>
          <p>Get a full refund if you cancel within 24 hours of purchase</p>
        </b-form-group>
        <b-form-group>
          <h5 class="font-weight-bold">Waranty</h5>
          <p>
            We guarantee damages incurred during cleaning in the amount not exceeding 5,000 baht
          </p>
        </b-form-group>
        <b-button variant="primary" size="lg" block @click="checkout">Checkout</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import VSelect from '@alfsnd/vue-bootstrap-select'
export default {
  components: {
    VSelect
  },
  data() {
    return {}
  },
  methods: {
    checkout() {
      this.$router.push({ name: 'BookingReview' })
    }
  }
}
</script>
